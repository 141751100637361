#home-page {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.bg-f8bet {
    background-image: url('../images/bg_f8bet.png');
}

.bg-hi88 {
    background-image: url('../images/bg_hi88.png');
}

.bg-jun88 {
    background-image: url('../images/bg_jun88.png');
}

.sunrise {
    position: absolute;
    top: 0;
    left: 50%;
    height: 150%;
    z-index: 1;
    opacity: 0.4;
    animation: rotate 60s infinite linear;
}

@keyframes rotate {
    from {
        transform: translateX(-50%) translateY(-25%) rotate(0deg);
      }
      to {
        transform: translateX(-50%) translateY(-25%) rotate(359deg);
      }
}

.warpper {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 5;
}

.logo {
    width: 420px;
    margin: 30px;
}

.text-img {
    width: 500px;
    margin-bottom: -20px;
    margin-top: 20px;
}

.lixi {
    display: none;
}

.character-pic {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 3;
}

.input-cont label {
    color: white;
    font-weight: 600;
    font-size: 20px;
}

.input-cont {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 600px; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 500;
}

.input-cont-f8bet {
    background-image: url('../images/input_cont_f8bet.png');
}

.input-cont-jun88 {
    background-image: url('../images/input_cont_f8bet.png');
}

.input-cont-hi88 {
    margin-top: 20px;
    background-image: url('../images/input_cont_hi88.png');
    width: 550px;
    height: 300px;
}


.input-item-cont {
    width: 75%;
    margin: 0px 0 10px;
    height: 50px;
    background-color: #e4e4e4;
    border: 1px solid rgb(146, 146, 146);
    border-radius: 12px;
}

.input-item-cont input {
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding: 6px 24px;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;
}

.input-item-cont input:focus {
    outline: none;
}

#submit-btn {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 600;
    font-size: 1.1em; 
    margin-bottom: 30px;
}

.submit-btn-f8bet {
    background-image: url('../images/button-f8bet.png');
    width: 256px;
    height: 55px;
}

.submit-btn-hi88 {
    background-image: url('../images/button-hi88.png');
    width: 256px;
    height: 65px;
    padding-left: 40px;
    padding-bottom: 5px;
}

.submit-btn-f8bet:hover {
    background-image: url('../images/buttonhover-f8bet.png');
}

.submit-btn-hi88:hover {
    background-image: url('../images/buttonhover-hi88.png');
}

#promo-detail-btn {
    margin-top: 24px;
    font-family: 'Montserrat', sans-serif;
    color: #4d95b1;
    font-weight: 600;
    animation: change-background 3s ease infinite;
    font-size: 16px;
}

@keyframes change-background {
    0% {
        color: #ebd93d;
    }
    50% {
        color: #eb572a;
    }
    100% {
        color: #ffffff;

    }
}

#promo-detail-btn:hover {
    cursor: pointer;
    color: #7a2421;
}

.promotion-modal {
    font-family: 'Times New Roman', Times, serif;
}

.header-popup {
    text-align: start !important;
    width: 100%;
}

.promo-popup {
    line-height: 30px;
}

.modal-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.modal-text-cont {
    display: flex;
}

table tr, table td {
    border: 1px solid white !important;
    color: white !important;
}

.input-form input,
.input-form-captcha input {
    padding: 0 20px;
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgb(206, 206, 206);
    border-radius: 10px;
    letter-spacing: 1px;
}

.input-form-captcha-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
}

.input-form-captcha {
    width: 50%;
    height: 36px;
    margin-bottom: 24px;
}

#captcha-input,
.input-form input:focus {
    outline: none;
}

#captcha {
    width: 80px;
    margin-right: 16px;
    height: 36px;
    border-radius: 5px;
    font-size: 1.2em;
    background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 3px;
    font-family: 'Mulish', sans-serif;
    font-style: italic;
    text-decoration: line-through;
    color: rgb(255, 255, 255);
}

.box-img {
    padding: 70px 0;
}

.box-label {
    padding: 20px;
}

.div-support {
    margin-bottom: 20px;   
    color: #000;
    font-size: 18px;
    font-weight: 500;   
    width: 78%;
} 
.support-content {
    font-size: 15px;
    font-weight: 500;
    padding-bottom: 5px;
}

.support-content span {
    font-style: italic;
    font-weight: 400; 
}

.example { 
    font-weight: 600;
}
@media only screen and (max-width: 750px) {    
    .logo {
        width: 280px;
    }

    .text-img {
        margin-top: -5px;
        width: 300px;
    }

    .input-cont {
        margin: auto;
        margin-top: 20px;
        width: 95%; 
        z-index: 500;
    }


    #promo-detail-btn {
        margin-top: 20px;
        font-size: 14px;
    }

    #submit-btn {
        width: 170px;
        height: 40px;
        font-size: 0.9em;
    }

    .input-item-cont {
        width: 260px;
        margin: 16px 0 5px 0;
        height: 30px;
    }

    .box-img {
        width: 95%;
        margin: auto;
    }

    .input-cont label {
        padding: 30px 0 10px 0;
        font-size:  13px;
        text-align: center;
    }

    .submit-btn-hi88 {
        width: 256px !important;
        height: 55px !important;
        padding-left: 40px;
        padding-bottom: 5px;
    }

    .div-support { 
        font-size: 13px; 
    }
    .support-content {
        font-size: 13px; 
    }
    
    .input-item-cont input {
        background-color: transparent; 
        letter-spacing: -1px;
    }
    .modal { 
        width: 85%; 
    }
}
